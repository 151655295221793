.HLP-Hero-pre-heading,
.HLP-Hero-pre-heading--bar-tiles,
.HLP-hero__left-aligned-tiles-wrapper-dark
  .HLP-hero__left-aligned-tiles-pre-heading {
  color: var(--text-main-on-dark);
}

.HLP-hero-trust-elements span,
.HLP-hero-trust-elements div {
  color: var(--text-main-on-dark);
}

.HLP-hero-trust-elements
  .HLP-hero-rating-star:not(.HLP-hero-rating-star--half-cover)
  use {
  fill: var(--text-main-on-dark);
}

.HLP-block .FooterCTA-heading {
  color: var(--text-main-on-dark);
}
